import React from 'react';
import { Outlet, RouteObject } from 'react-router';

const EditStoneworkMarking = React.lazy(
  () =>
    import(
      './components/edit-stonework-marking/edit-stonework-marking.component'
    ),
);
const StoneworkMarkingDetails = React.lazy(
  () =>
    import(
      './components/stonework-marking-details/stonework-marking-details.component'
    ),
);

const StoneworkMarkingsList = React.lazy(
  () =>
    import(
      './components/stonework-markings-list/stonework-markings-list.component'
    ),
);

const CreateStoneworkMarking = React.lazy(
  () =>
    import(
      './components/create-stonework-marking/create-stonework-marking.component'
    ),
);

const route: RouteObject = {
  path: 'stonework-markings',
  element: <Outlet />,
  children: [
    { index: true, element: <StoneworkMarkingsList /> },
    { path: 'create', element: <CreateStoneworkMarking /> },
    { path: 'edit/:id', element: <EditStoneworkMarking /> },
    { path: ':id', element: <StoneworkMarkingDetails /> },
  ],
};

export default route;
