import { styled } from '@mui/material/styles';

export const MapWrapper = styled('div')`
  height: calc(100vh - 188px);
  position: relative;
  transition: all 1s;
  &.full-screen {
    transition: all 1s;
    height: 100vh;
  }
`;

export const FullScreenIconWrapper = styled('div')`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
`;
