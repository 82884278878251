import { Menu } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { getAuthenticatedUser } from '../../../auth/api';
import {
  MainMenuItem,
  MenuButton,
  MenuLink,
} from '../../../common/components/navbar/navbar.styles';
import { MenuItemsWrapper } from './menu-items.styles';

type AnchorSetter = React.Dispatch<React.SetStateAction<HTMLElement | null>>;

const MenuItems: React.FC = () => {
  const { data } = useQuery({
    queryKey: ['user'],
    queryFn: getAuthenticatedUser,
    retry: false,
    refetchOnWindowFocus: true,
  });

  const [anchorBrowse, setAnchorBrowse] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorCreate, setAnchorCreate] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorUsers, setAnchorUsers] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorVocabulary, setAnchorVocabulary] =
    React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu =
    (set: AnchorSetter) => (event: React.MouseEvent<HTMLElement>) => {
      set(event.currentTarget);
    };

  const handleCloseNavMenu = (set: AnchorSetter) => () => {
    set(null);
  };

  return (
    <MenuItemsWrapper>
      <MenuButton id="open-browse" onClick={handleOpenNavMenu(setAnchorBrowse)}>
        Browse
      </MenuButton>
      <Menu
        id="browse"
        PopoverClasses={{ root: 'menu-subitem' }}
        anchorEl={anchorBrowse}
        keepMounted
        open={Boolean(anchorBrowse)}
        onClose={handleCloseNavMenu(setAnchorBrowse)}
      >
        <MainMenuItem onClick={handleCloseNavMenu(setAnchorBrowse)}>
          <MenuLink to="/admin/attestations">attestations</MenuLink>
        </MainMenuItem>
        <MainMenuItem onClick={handleCloseNavMenu(setAnchorBrowse)}>
          <MenuLink to="/admin/loci">loci</MenuLink>
        </MainMenuItem>
        <MainMenuItem onClick={handleCloseNavMenu(setAnchorBrowse)}>
          <MenuLink to="/admin/people">people</MenuLink>
        </MainMenuItem>
        <MainMenuItem onClick={handleCloseNavMenu(setAnchorBrowse)}>
          <MenuLink to="/admin/stones">stones</MenuLink>
        </MainMenuItem>
        <MainMenuItem onClick={handleCloseNavMenu(setAnchorBrowse)}>
          <MenuLink to="/admin/stonework-markings">stonework markings</MenuLink>
        </MainMenuItem>
        <MainMenuItem onClick={handleCloseNavMenu(setAnchorBrowse)}>
          <MenuLink to="/admin/styles">styles</MenuLink>
        </MainMenuItem>
        <MainMenuItem onClick={handleCloseNavMenu(setAnchorBrowse)}>
          <MenuLink to="/admin/workshops">workshops</MenuLink>
        </MainMenuItem>
        <MainMenuItem
          onClick={handleCloseNavMenu(setAnchorBrowse)}
        ></MainMenuItem>
      </Menu>
      <MenuButton id="open-create" onClick={handleOpenNavMenu(setAnchorCreate)}>
        Create
      </MenuButton>
      <Menu
        id="create"
        PopoverClasses={{ root: 'menu-subitem' }}
        anchorEl={anchorCreate}
        keepMounted
        open={Boolean(anchorCreate)}
        onClose={handleCloseNavMenu(setAnchorCreate)}
      >
        <MainMenuItem onClick={handleCloseNavMenu(setAnchorCreate)}>
          <MenuLink to="/admin/attestations/create">attestation</MenuLink>
        </MainMenuItem>
        <MainMenuItem onClick={handleCloseNavMenu(setAnchorCreate)}>
          <MenuLink to="/admin/loci/create">locus</MenuLink>
        </MainMenuItem>
        <MainMenuItem onClick={handleCloseNavMenu(setAnchorCreate)}>
          <MenuLink to="/admin/people/create">person</MenuLink>
        </MainMenuItem>
        <MainMenuItem onClick={handleCloseNavMenu(setAnchorCreate)}>
          <MenuLink to="/admin/stones/create">stone</MenuLink>
        </MainMenuItem>
        <MainMenuItem onClick={handleCloseNavMenu(setAnchorCreate)}>
          <MenuLink to="/admin/stonework-markings/create">
            stonework markings
          </MenuLink>
        </MainMenuItem>
        <MainMenuItem onClick={handleCloseNavMenu(setAnchorCreate)}>
          <MenuLink to="/admin/styles/create">style</MenuLink>
        </MainMenuItem>
        <MainMenuItem onClick={handleCloseNavMenu(setAnchorCreate)}>
          <MenuLink to="/admin/workshops/create">workshop</MenuLink>
        </MainMenuItem>
        <MainMenuItem
          onClick={handleCloseNavMenu(setAnchorCreate)}
        ></MainMenuItem>
      </Menu>
      {data?.role === 'ADMIN' && (
        <>
          <MenuButton
            id="open-users"
            onClick={handleOpenNavMenu(setAnchorUsers)}
          >
            users
          </MenuButton>
          <Menu
            id="users"
            PopoverClasses={{ root: 'menu-subitem' }}
            anchorEl={anchorUsers}
            keepMounted
            open={Boolean(anchorUsers)}
            onClose={handleCloseNavMenu(setAnchorUsers)}
          >
            <MainMenuItem onClick={handleCloseNavMenu(setAnchorUsers)}>
              <MenuLink to="/admin/users">Browse</MenuLink>
            </MainMenuItem>
            <MainMenuItem onClick={handleCloseNavMenu(setAnchorUsers)}>
              <MenuLink to="/admin/users/register">Register new</MenuLink>
            </MainMenuItem>
            <MainMenuItem
              onClick={handleCloseNavMenu(setAnchorUsers)}
            ></MainMenuItem>
          </Menu>
        </>
      )}
      <MenuButton
        id="open-vocabulary"
        onClick={handleOpenNavMenu(setAnchorVocabulary)}
      >
        Vocabulary
      </MenuButton>
      <Menu
        id="vocabulary"
        PopoverClasses={{ root: 'menu-subitem' }}
        anchorEl={anchorVocabulary}
        keepMounted
        open={Boolean(anchorVocabulary)}
        onClose={handleCloseNavMenu(setAnchorVocabulary)}
      >
        <MainMenuItem onClick={handleCloseNavMenu(setAnchorVocabulary)}>
          <MenuLink to="/admin/vocabulary">browse</MenuLink>
        </MainMenuItem>
        <MainMenuItem onClick={handleCloseNavMenu(setAnchorVocabulary)}>
          <MenuLink to="/admin/vocabulary/create">create</MenuLink>
        </MainMenuItem>
      </Menu>
      <MenuLink to="/admin/users/me">
        <MenuButton>account</MenuButton>
      </MenuLink>
      <MenuLink to="/auth/logout">
        <MenuButton>logout</MenuButton>
      </MenuLink>
    </MenuItemsWrapper>
  );
};

export default MenuItems;
