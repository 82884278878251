import React from 'react';
import { RouteObject } from 'react-router';

import AtlasOutlet from '../../../common/components/atlas-outlet/atlas-outlet.component';

const AllPeople = React.lazy(
  () => import('./components/all/all-people.component'),
);
const PeopleDetails = React.lazy(
  () => import('./components/details/people-details.component'),
);

const route: RouteObject = {
  path: 'people',
  element: <AtlasOutlet pageType="people" />,
  children: [
    { index: true, element: <AllPeople /> },
    { path: ':id', element: <PeopleDetails /> },
  ],
};

export default route;
