import React, { ReactNode } from 'react';

import { BrandingLink, Logo, MainAppBar, MainToolbar } from './navbar.styles';

type Props = {
  children?: ReactNode;
  classes?: string;
};

const Navbar: React.FC<Props> = ({ children, classes }) => {
  return (
    <MainAppBar position="sticky" as="nav" className={classes}>
      <MainToolbar disableGutters>
        <BrandingLink to="https://stonemasters.uw.edu.pl">
          <Logo src="/logo_small.webp" alt="StoneMasters" />
        </BrandingLink>
        <BrandingLink to="https://stonemasters.uw.edu.pl">
          <Logo src="/logo_WH_inverted.webp" alt="wh uw" />
        </BrandingLink>
        {children}
      </MainToolbar>
    </MainAppBar>
  );
};

export default Navbar;
