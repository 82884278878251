import React from 'react';
import { Outlet, RouteObject } from 'react-router';

const CreateStone = React.lazy(
  () => import('./components/create-stone/create-stone.component'),
);
const EditStone = React.lazy(
  () => import('./components/edit-stone/edit-stone.component'),
);
const StonesList = React.lazy(
  () => import('./components/stones-list/stones-list.component'),
);

const StonesDetails = React.lazy(
  () => import('./components/stone-details/stone-details.component'),
);

const route: RouteObject = {
  path: 'stones',
  element: <Outlet />,
  children: [
    { index: true, element: <StonesList /> },
    { path: 'create', element: <CreateStone /> },
    { path: 'edit/:id', element: <EditStone /> },
    { path: ':id', element: <StonesDetails /> },
  ],
};

export default route;
