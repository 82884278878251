import React from 'react';
import ReactLoading from 'react-loading';

import { LoaderBox } from './loader.styles';

const Loader: React.FC = () => (
  <LoaderBox>
    <ReactLoading type={'bubbles'} />
  </LoaderBox>
);

export default Loader;
