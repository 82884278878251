import { Box, Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';

import { ContentWrapper } from '../../styles/global.styles';
import Navbar from '../navbar/navbar.component';
import {
  NoMatchAttribution,
  NoMatchBackLink,
  NoMatchImage,
} from './no-match.styles';

const NoMatch: React.FC = () => {
  return (
    <>
      <Navbar />
      <ContentWrapper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: ' calc(100vh - 188px)',
          }}
        >
          <Container maxWidth="md">
            <Grid container spacing={2}>
              <Grid xs={6}>
                <Typography variant="h1">404</Typography>
                <Typography variant="h6">
                  The page you’re looking for doesn’t exist.
                </Typography>
                <NoMatchBackLink to="/">
                  <Button variant="contained">Back Home</Button>
                </NoMatchBackLink>
              </Grid>
              <Grid xs={6}>
                <NoMatchImage src="/404.jpg" alt="" style={{ width: '100%' }} />
                <NoMatchAttribution>
                  <a href="https://www.freepik.com/free-vector/404-error-with-cute-animal-concept-illustration_7967795.htm#query=404&position=34&from_view=keyword&track=sph">
                    Image by storyset
                  </a>{' '}
                  on Freepik
                </NoMatchAttribution>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </ContentWrapper>
    </>
  );
};

export default NoMatch;
