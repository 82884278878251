import React from 'react';
import { Navigate, RouteObject } from 'react-router';

import Layout from './components/layout/layout.component';
import attestations from './routes/attestations';
import loci from './routes/loci';
import people from './routes/people';
import stones from './routes/stones';
import stonework from './routes/stonework';
import styles from './routes/styles';
import workshops from './routes/workshops';

const route: RouteObject = {
  path: 'atlas',
  element: <Layout />,
  children: [
    { index: true, element: <Navigate to="/atlas/workshops" /> },
    workshops,
    stones,
    people,
    attestations,
    loci,
    stonework,
    styles,
  ],
};

export default route;
