import React from 'react';
import { Outlet, RouteObject } from 'react-router';

const AttestationDetails = React.lazy(
  () =>
    import('./components/attestation-details/attestation-details.component'),
);
const CreateAttestation = React.lazy(
  () => import('./components/create-attestation/create-attestation.component'),
);
const EditAttestation = React.lazy(
  () => import('./components/edit-attestation/edit-attestation.component'),
);

const AttestationsListLayout = React.lazy(
  () => import('./components/attestations-list/attestations-list.component'),
);

const route: RouteObject = {
  path: 'attestations',
  element: <Outlet />,
  children: [
    { index: true, element: <AttestationsListLayout /> },
    { path: 'create', element: <CreateAttestation /> },
    { path: 'edit/:id', element: <EditAttestation /> },
    { path: ':id', element: <AttestationDetails /> },
  ],
};

export default route;
