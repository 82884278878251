import { configureStore } from '@reduxjs/toolkit';

import AtlasReducer from './modules/atlas/slices/atlas.slice';
import GlobalReducer from './modules/common/slices/global.slice';

export const store = configureStore({
  reducer: {
    global: GlobalReducer,
    atlas: AtlasReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
