import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Navigate } from 'react-router';

import GlobalLoader from '../../../common/components/global-loader/global-loader.component';
import { getAuthenticatedUser } from '../../api';

export function withAuth(WrappedComponent: React.ComponentType) {
  const Component = () => {
    const { isLoading, isError } = useQuery({
      queryKey: ['user'],
      queryFn: getAuthenticatedUser,
      retry: false,
      refetchOnWindowFocus: true,
    });

    if (isError) {
      return <Navigate to="/auth/login" />;
    }

    return (
      <GlobalLoader loading={isLoading}>
        <WrappedComponent />
      </GlobalLoader>
    );
  };
  return Component;
}
