import React from 'react';
import { Outlet, RouteObject } from 'react-router';

const StylesList = React.lazy(
  () => import('./components/styles-list/styles-list.component'),
);
const CreateStyle = React.lazy(
  () => import('./components/create-style/create-style.component'),
);
const EditStyle = React.lazy(
  () => import('./components/edit-style/edit-style.component'),
);
const StyleDetails = React.lazy(
  () => import('./components/style-details/style-details.component'),
);

const route: RouteObject = {
  path: 'styles',
  element: <Outlet />,
  children: [
    { index: true, element: <StylesList /> },
    { path: 'create', element: <CreateStyle /> },
    { path: 'edit/:id', element: <EditStyle /> },
    { path: ':id', element: <StyleDetails /> },
  ],
};

export default route;
