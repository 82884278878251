import React from 'react';
import { RouteObject } from 'react-router';

import AtlasOutlet from '../../../common/components/atlas-outlet/atlas-outlet.component';

const AllAttestations = React.lazy(
  () => import('./components/all/all-attestations.component'),
);
const AttestationsDetails = React.lazy(
  () => import('./components/details/attestation-details.component'),
);

const route: RouteObject = {
  path: 'attestations',
  element: <AtlasOutlet pageType="attestations" />,
  children: [
    { index: true, element: <AllAttestations /> },
    { path: ':id', element: <AttestationsDetails /> },
  ],
};

export default route;
