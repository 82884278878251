import React from 'react';
import { Navigate, RouteObject } from 'react-router';

import { withAuth } from '../auth/components/with-auth/with-auth.component';
import Layout from './components/layout/layout.component';
import attestations from './routes/attestations';
import loci from './routes/loci';
import people from './routes/people';
import stones from './routes/stones';
import stoneworkMarkings from './routes/stonework-markings';
import styles from './routes/styles';
import users from './routes/users';
import vocabulary from './routes/vocabulary';
import workshops from './routes/workshops';

const Element = withAuth(Layout);

const route: RouteObject = {
  path: 'admin',
  element: <Element />,
  children: [
    { index: true, element: <Navigate to="/admin/attestations" /> },
    attestations,
    loci,
    people,
    stones,
    stoneworkMarkings,
    styles,
    users,
    workshops,
    vocabulary,
  ],
};

export default route;
