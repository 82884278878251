import { Breadcrumbs, Container, Link, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink, Location, useLocation } from 'react-router-dom';

const Breadcrumb: React.FC = () => {
  const getBreadcrumbs = (location: Location) => {
    const base = '/';
    return location.pathname
      .split('/')
      .filter((part) => !!part)
      .map((pathPart, i, arr) => ({
        label: pathPart,
        path: `${base}${i === 0 ? pathPart : arr.slice(0, i + 1).join('/')}`,
      }));
  };
  const location = useLocation();

  return (
    <Container maxWidth="lg">
      <Breadcrumbs aria-label="Breadcrumb">
        {getBreadcrumbs(location).map((value, index, arr) => {
          const last = index === arr.length - 1;
          const to = value.path;

          return last || value.label === 'edit' ? (
            <Typography color="textPrimary" key={to}>
              {value.label}
            </Typography>
          ) : (
            <Link color="inherit" component={RouterLink} to={to} key={to}>
              {value.label}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Container>
  );
};

export default Breadcrumb;
