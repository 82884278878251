import React from 'react';
import { RouteObject } from 'react-router';

import AtlasOutlet from '../../../common/components/atlas-outlet/atlas-outlet.component';

const AllLoci = React.lazy(() => import('./components/all/all-loci.component'));
const LocusDetails = React.lazy(
  () => import('./components/details/loci-details.component'),
);

const route: RouteObject = {
  path: 'loci',
  element: <AtlasOutlet pageType="loci" />,
  children: [
    { index: true, element: <AllLoci /> },
    { path: ':id', element: <LocusDetails /> },
  ],
};

export default route;
