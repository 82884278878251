import { createSlice } from '@reduxjs/toolkit';
import { FeatureCollection, Point } from 'geojson';

import { RootState } from '../../../store';

interface AtlasState {
  pageType:
    | 'attestations'
    | 'people'
    | 'loci'
    | 'stones'
    | 'stonework-markings'
    | 'styles'
    | 'workshops';
  items: FeatureCollection<Point>;
  showMenu: boolean;
  showList: boolean;
}

const initialState: AtlasState = {
  pageType: 'attestations',
  items: {
    type: 'FeatureCollection',
    features: [],
  },
  showMenu: true,
  showList: false,
};

export const AtlasSlice = createSlice({
  name: 'atlas',
  initialState,
  reducers: {
    setPageType: (state, action) => {
      state.pageType = action.payload;
    },
    setItems: (state, action) => {
      state.items = action.payload;
    },
    setShowMenu: (state, action) => {
      state.showMenu = action.payload;
    },
    setShowList: (state, action) => {
      state.showList = action.payload;
    },
  },
});

export const { setItems, setPageType, setShowMenu, setShowList } =
  AtlasSlice.actions;

export const selectItems = (state: RootState) => state.atlas.items;
export const selectPageType = (state: RootState) => state.atlas.pageType;
export const selectShowMenu = (state: RootState) => state.atlas.showMenu;
export const selectShowList = (state: RootState) => state.atlas.showList;

export default AtlasSlice.reducer;
