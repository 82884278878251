import { FeatureCollection, Point } from 'geojson';
import Feature from 'ol/Feature';
import VectorPoint from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import { transform } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import View from 'ol/View';

export function RenderAttestationsLayer(
  map: Map,
  items: FeatureCollection<Point>,
  baseView: View,
): void {
  const vectorSource = new VectorSource();
  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({ color: 'rgba(255, 0, 0, 1)' }),
        stroke: new Stroke({ color: 'rgb(0, 0, 0)', width: 1 }),
      }),
    }),
  });
  for (const item of items.features) {
    vectorSource.addFeature(
      new Feature({
        geometry: new VectorPoint(
          transform(item.geometry.coordinates, 'EPSG:4326', 'EPSG:3857'),
        ),
        name: 'Site',
        ...item.properties,
      }),
    );
  }
  if (items.features.length > 0) {
    map.getView().fit(vectorSource.getExtent() ?? baseView.calculateExtent(), {
      size: map.getSize(),
      maxZoom: 20,
    });
  }
  map.addLayer(vectorLayer);
}

export function RenderPeopleLayer(
  map: Map,
  items: FeatureCollection<Point>,
  baseView: View,
): void {
  const vectorSource = new VectorSource();
  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({ color: 'rgba(255, 0, 0, 1)' }),
        stroke: new Stroke({ color: 'rgb(0, 0, 0)', width: 1 }),
      }),
    }),
  });
  for (const item of items.features) {
    vectorSource.addFeature(
      new Feature({
        geometry: new VectorPoint(
          transform(item.geometry.coordinates, 'EPSG:4326', 'EPSG:3857'),
        ),
        name: 'Site',
        ...item.properties,
      }),
    );
  }
  if (items.features.length > 0) {
    map.getView().fit(vectorSource.getExtent() ?? baseView.calculateExtent(), {
      size: map.getSize(),
      maxZoom: 20,
    });
  }
  map.addLayer(vectorLayer);
}

export function RenderLociLayer(
  map: Map,
  items: FeatureCollection<Point>,
  baseView: View,
): void {
  const vectorSource = new VectorSource();
  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({ color: 'rgba(255, 0, 0, 1)' }),
        stroke: new Stroke({ color: 'rgb(0, 0, 0)', width: 1 }),
      }),
    }),
  });
  for (const item of items.features) {
    vectorSource.addFeature(
      new Feature({
        geometry: new VectorPoint(
          transform(item.geometry.coordinates, 'EPSG:4326', 'EPSG:3857'),
        ),
        name: 'Site',
        ...item.properties,
      }),
    );
  }
  if (items.features.length > 0) {
    map.getView().fit(vectorSource.getExtent() ?? baseView.calculateExtent(), {
      size: map.getSize(),
      maxZoom: 20,
    });
  }
  map.addLayer(vectorLayer);
}

export function RenderStonesLayer(
  map: Map,
  items: FeatureCollection<Point>,
  baseView: View,
): void {
  const vectorSource = new VectorSource();
  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({ color: 'rgba(255, 0, 0, 1)' }),
        stroke: new Stroke({ color: 'rgb(0, 0, 0)', width: 1 }),
      }),
    }),
  });
  for (const item of items.features) {
    vectorSource.addFeature(
      new Feature({
        geometry: new VectorPoint(
          transform(item.geometry.coordinates, 'EPSG:4326', 'EPSG:3857'),
        ),
        name: 'Site',
        ...item.properties,
      }),
    );
  }
  if (items.features.length > 0) {
    map.getView().fit(vectorSource.getExtent() ?? baseView.calculateExtent(), {
      size: map.getSize(),
      maxZoom: 20,
    });
  }
  map.addLayer(vectorLayer);
}

export function RenderStoneworkMarkingsLayer(
  map: Map,
  items: FeatureCollection<Point>,
  baseView: View,
): void {
  const vectorSource = new VectorSource();
  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({ color: 'rgba(255, 0, 0, 1)' }),
        stroke: new Stroke({ color: 'rgb(0, 0, 0)', width: 1 }),
      }),
    }),
  });
  for (const item of items.features) {
    vectorSource.addFeature(
      new Feature({
        geometry: new VectorPoint(
          transform(item.geometry.coordinates, 'EPSG:4326', 'EPSG:3857'),
        ),
        name: 'Site',
        ...item.properties,
      }),
    );
  }
  if (items.features.length > 0) {
    map.getView().fit(vectorSource.getExtent() ?? baseView.calculateExtent(), {
      size: map.getSize(),
      maxZoom: 20,
    });
  }
  map.addLayer(vectorLayer);
}

export function RenderStylesLayer(
  map: Map,
  items: FeatureCollection<Point>,
  baseView: View,
): void {
  const vectorSource = new VectorSource();
  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({ color: 'rgba(255, 0, 0, 1)' }),
        stroke: new Stroke({ color: 'rgb(0, 0, 0)', width: 1 }),
      }),
    }),
  });
  for (const item of items.features) {
    vectorSource.addFeature(
      new Feature({
        geometry: new VectorPoint(
          transform(item.geometry.coordinates, 'EPSG:4326', 'EPSG:3857'),
        ),
        name: 'Site',
        ...item.properties,
      }),
    );
  }
  if (items.features.length > 0) {
    map.getView().fit(vectorSource.getExtent() ?? baseView.calculateExtent(), {
      size: map.getSize(),
      maxZoom: 20,
    });
  }
  map.addLayer(vectorLayer);
}

export function RenderWorkshopsLayer(
  map: Map,
  items: FeatureCollection<Point>,
  baseView: View,
): void {
  const vectorSource = new VectorSource();
  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({ color: 'rgba(255, 0, 0, 1)' }),
        stroke: new Stroke({ color: 'rgb(0, 0, 0)', width: 1 }),
      }),
    }),
  });
  for (const item of items.features) {
    vectorSource.addFeature(
      new Feature({
        geometry: new VectorPoint(
          transform(item.geometry.coordinates, 'EPSG:4326', 'EPSG:3857'),
        ),
        name: 'Site',
        ...item.properties,
      }),
    );
  }
  if (items.features.length > 0) {
    map.getView().fit(vectorSource.getExtent() ?? baseView.calculateExtent(), {
      size: map.getSize(),
      maxZoom: 20,
    });
  }
  map.addLayer(vectorLayer);
}
