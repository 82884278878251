import React from 'react';
import { Outlet, RouteObject } from 'react-router';

const CreatePerson = React.lazy(
  () => import('./components/create-person/create-person.component'),
);
const PeopleList = React.lazy(
  () => import('./components/people-list/people-list.component'),
);
const EditPerson = React.lazy(
  () => import('./components/edit-person/edit-person.component'),
);
const PersonDetails = React.lazy(
  () => import('./components/person-details/person-details.component'),
);

const route: RouteObject = {
  path: 'people',
  element: <Outlet />,
  children: [
    { index: true, element: <PeopleList /> },
    { path: 'create', element: <CreatePerson /> },
    { path: 'edit/:id', element: <EditPerson /> },
    { path: ':id', element: <PersonDetails /> },
  ],
};

export default route;
