import React from 'react';
import { RouteObject } from 'react-router';

import AtlasOutlet from '../../../common/components/atlas-outlet/atlas-outlet.component';

const AllStyles = React.lazy(
  () => import('./components/all/all-styles.component'),
);
const StylesDetails = React.lazy(
  () => import('./components/details/styles-details.component'),
);

const route: RouteObject = {
  path: 'styles',
  element: <AtlasOutlet pageType="styles" />,
  children: [
    { index: true, element: <AllStyles /> },
    { path: ':id', element: <StylesDetails /> },
  ],
};

export default route;
