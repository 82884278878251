import httpClient from '../../common/http-client';
import { LoginCredentials } from '../types/login-credentials.interface';
import { User } from '../types/user.interface';

export const getAuthenticatedUser = async () => {
  const response = await httpClient.get<User>('/auth', {
    withCredentials: true,
  });
  return response.data;
};

export const postLoginCredentials = async (data: LoginCredentials) => {
  const response = await httpClient.post<User>('/auth/login', data, {
    withCredentials: true,
  });
  return response.data;
};

export const postLogout = async () => {
  const response = await httpClient.post<void>('/auth/logout', undefined, {
    withCredentials: true,
  });
  return response.data;
};
