import React from 'react';
import { RouteObject } from 'react-router';

import AtlasOutlet from '../../../common/components/atlas-outlet/atlas-outlet.component';

const AllStones = React.lazy(
  () => import('./components/all/all-stones.component'),
);
const StonesDetails = React.lazy(
  () => import('./components/details/stone-details.component'),
);

const route: RouteObject = {
  path: 'stones',
  element: <AtlasOutlet pageType="stones" />,
  children: [
    { index: true, element: <AllStones /> },
    { path: ':id', element: <StonesDetails /> },
  ],
};

export default route;
