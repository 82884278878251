import React from 'react';
import { Outlet, RouteObject } from 'react-router';

const CreateVocabulary = React.lazy(
  () => import('./components/create-vocabulary/create-vocabulary.component'),
);

const UpdateVocabulary = React.lazy(
  () => import('./components/update-vocabulary/update-vocabulary.component'),
);

const VocabulariesList = React.lazy(
  () => import('./components/vocabularies-list/vocabularies-list.component'),
);

const route: RouteObject = {
  path: 'vocabulary',
  element: <Outlet />,
  children: [
    { index: true, element: <VocabulariesList /> },
    { path: 'create', element: <CreateVocabulary /> },
    { path: ':id', element: <UpdateVocabulary /> },
  ],
};

export default route;
