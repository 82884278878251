import React, { Suspense } from 'react';
import { Outlet } from 'react-router';

import Breadcrumb from '../../../common/components/breadcrumb/breadcrumb.component';
import Loader from '../../../common/components/loader/loader.component';
import Navbar from '../../../common/components/navbar/navbar.component';
import { ContentWrapper } from '../../../common/styles/global.styles';
import MenuItems from '../menu-items/menu-items.component';

const Layout: React.FC = () => {
  return (
    <>
      <Navbar>
        <MenuItems />
      </Navbar>
      <ContentWrapper>
        <Suspense fallback={<Loader />}>
          <Breadcrumb />
          <Outlet />
        </Suspense>
      </ContentWrapper>
    </>
  );
};

export default Layout;
