import {
  Box,
  Card,
  Container,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const Main = styled(Box)`
  min-height: 100vh;
`;

export const ContentWrapper = styled(Box)`
  min-height: calc(100vh - 188px);
`;

export const DetailsRowWrapper = styled(Box)`
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const DetailsRowItem = styled(Box)`
  width: 25%;
`;

export const DetailsRow = styled(Box)`
  margin-bottom: 20px;
`;

export const DetailsRowTopSpaced = styled(Box)`
  margin-top: 20px;
`;

export const AutocompleteWrapper = styled(FormControl)`
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const AutocompleteInputsWrapper = styled(Box)`
  display: flex;
  align-items: flex-end;
`;

export const AutocompleteFormHelper = styled(FormHelperText)`
  margin-left: 0;
`;

export const FormContainer = styled(Container)`
  margin-bottom: 20px;
`;

export const ModalTitle = styled(DialogTitle)`
  min-width: 400px;
`;

export const CloseIconButton = styled(IconButton)`
  position: absolute;
  right: 8;
  top: 8;
`;

export const HalfWidthBox = styled(Box)`
  width: 49%;
`;

export const ImagesWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0;
  padding: 10px;
`;

export const ImageWrapper = styled(Box)`
  width: 23%;
  position: relative;
  padding: 0.5%;
  margin: 0.5%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeleteIconButton = styled(IconButton)`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
`;

export const ButtonWrapper = styled(Box)`
  margin-top: 20px;
`;

export const TreeNodeCard = styled(Card)`
  margin-bottom: 20px;
`;
