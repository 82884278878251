import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useNavigate } from 'react-router';

import { getAuthenticatedUser } from '../../api';

export function withAdmin(WrappedComponent: React.ComponentType) {
  const Component = () => {
    const { isLoading, data } = useQuery({
      queryKey: ['user'],
      queryFn: getAuthenticatedUser,
      retry: false,
      refetchOnWindowFocus: true,
    });

    const nav = useNavigate();

    if (isLoading) {
      return <span>Loading...</span>;
    }

    if (data?.role !== 'ADMIN') {
      nav(-1);
    }

    return <WrappedComponent />;
  };
  return Component;
}
