import React from 'react';
import { RouteObject } from 'react-router';

import AtlasOutlet from '../../../common/components/atlas-outlet/atlas-outlet.component';

const AllWorkshops = React.lazy(
  () => import('./components/all/all-workshops.component'),
);
const WorkshopDetails = React.lazy(
  () => import('./components/details/workshop-details.component'),
);

const route: RouteObject = {
  path: 'workshops',
  element: <AtlasOutlet pageType="workshops" />,
  children: [
    { index: true, element: <AllWorkshops /> },
    { path: ':id', element: <WorkshopDetails /> },
  ],
};

export default route;
