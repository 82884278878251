import React from 'react';
import { Outlet, RouteObject } from 'react-router';

import { withAdmin } from '../../../auth/components/with-admin/with-admin';
const Account = React.lazy(
  () => import('./components/account/account.component'),
);
const CreateUser = React.lazy(
  () => import('./components/create-user/create-user.component'),
);
const EditUser = React.lazy(
  () => import('./components/edit-user/edit-user.component'),
);
const UsersList = React.lazy(
  () => import('./components/users-list/users-list.component'),
);

const WrappedHome = withAdmin(Outlet);

const route: RouteObject = {
  path: 'users',
  element: <WrappedHome />,
  children: [
    { index: true, element: <UsersList /> },
    { path: 'register', element: <CreateUser /> },
    { path: 'edit/:id', element: <EditUser /> },
    { path: 'me', element: <Account /> },
  ],
};

export default route;
