import { createTheme } from '@mui/material';

export const MainTheme = createTheme({
  palette: {
    primary: {
      main: '#002854',
    },
    secondary: {
      main: '#d03b00',
    },
    info: {
      main: '#0e4d8f',
      light: '#0e4d8f',
      dark: '#0e4d8f',
    },
  },
});
