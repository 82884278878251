import React from 'react';
import { RouteObject } from 'react-router';

import AtlasOutlet from '../../../common/components/atlas-outlet/atlas-outlet.component';

const AllStonework = React.lazy(
  () => import('./components/all/all-stonework.component'),
);
const StoneworkDetails = React.lazy(
  () => import('./components/details/stonework-details.component'),
);

const route: RouteObject = {
  path: 'stonework-markings',
  element: <AtlasOutlet pageType="stonework-markings" />,
  children: [
    { index: true, element: <AllStonework /> },
    { path: ':id', element: <StoneworkDetails /> },
  ],
};

export default route;
