import React, { Suspense } from 'react';
import { Outlet } from 'react-router';

import Loader from '../../../common/components/loader/loader.component';
import Navbar from '../../../common/components/navbar/navbar.component';
import { ContentWrapper } from '../../../common/styles/global.styles';
import { AuthContainer, AuthWrapper } from './auth-layout.styles';

const AuthLayout: React.FC = () => {
  return (
    <>
      <Navbar />
      <ContentWrapper>
        <AuthWrapper>
          <AuthContainer maxWidth="sm" disableGutters>
            <Suspense fallback={<Loader />}>
              <Outlet />
            </Suspense>
          </AuthContainer>
        </AuthWrapper>
      </ContentWrapper>
    </>
  );
};

export default AuthLayout;
