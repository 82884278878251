import { defaults as defaultControls, ScaleLine } from 'ol/control';
import TileLayer from 'ol/layer/Tile';
import Map from 'ol/Map';
import { BingMaps } from 'ol/source';
import View from 'ol/View';

export const mapFactory = (mapRef: React.RefObject<HTMLDivElement>) => {
  const source = new BingMaps({
    key: 'AiHNi7mayoLTslMt4YUgi_vNPcb8PPlitpd9-oAEWtlJel0ycNNNYQ-DBPhbKhJm',
    imagerySet: 'Aerial',
  });
  const map = new Map({
    controls: defaultControls().extend([
      new ScaleLine({
        units: 'metric',
        bar: false,
        text: true,
        minWidth: 140,
      }),
    ]),
    target: mapRef.current || undefined,
    layers: [new TileLayer({ source })],
    view: new View({
      center: [4185385.9208, 3921831.0473],
      zoom: 7,
    }),
  });
  return map;
};
