import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../../store';

interface GlobalState {
  hideNav: boolean;
}

const initialState: GlobalState = {
  hideNav: false,
};

export const GlobalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    toggleNav: (state) => {
      state.hideNav = !state.hideNav;
    },
  },
});

export const { toggleNav } = GlobalSlice.actions;

export const selectHideNav = (state: RootState) => state.global.hideNav;

export default GlobalSlice.reducer;
