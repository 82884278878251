import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectHideNav } from '../../slices/global.slice';
import { FooterBox, InfoContainer } from './footer.styles';

const Footer: React.FC = () => {
  const hideNav = useSelector(selectHideNav);
  return (
    <FooterBox as="footer" className={hideNav ? 'hidden' : ''}>
      <InfoContainer>
        <Box>
          ERC StG 101040152. Funded by the European Union. Views and opinions
          expressed are however those of the author(s) only and do not
          necessarily reflect those of the European Union or the European
          Research Council Executive Agency (ERCEA). Neither the European Union
          nor the granting authority can be held responsible for them.
        </Box>
        <Box>
          &copy; {new Date().getFullYear()} Faculty of History of the University
          of Warsaw
        </Box>
      </InfoContainer>
    </FooterBox>
  );
};

export default Footer;
