import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { setPageType } from '../../../atlas/slices/atlas.slice';

type Props = {
  pageType: string;
};

const AtlasOutlet: React.FC<Props> = ({ pageType }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageType(pageType));
  }, [dispatch, pageType]);
  return <Outlet />;
};

export default AtlasOutlet;
