import React from 'react';
import { RouteObject } from 'react-router';

import AuthLayout from './components/auth-layout/auth-layout.component';
const Login = React.lazy(() => import('./components/login/login.component'));
const Logout = React.lazy(() => import('./components/logout/logout.component'));

const routes: RouteObject = {
  path: 'auth',
  element: <AuthLayout />,
  children: [
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'logout',
      element: <Logout />,
    },
  ],
};

export default routes;
