import View from 'ol/View';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  RenderAttestationsLayer,
  RenderLociLayer,
  RenderPeopleLayer,
  RenderStonesLayer,
  RenderStoneworkMarkingsLayer,
  RenderStylesLayer,
  RenderWorkshopsLayer,
} from '../../common/utils/layer-renderer';
import { mapFactory } from '../../common/utils/map-factory';
import { selectItems, selectPageType } from '../slices/atlas.slice';

const useMap = (mapRef: React.RefObject<HTMLDivElement>): void => {
  const geojson = useSelector(selectItems);
  const pageType = useSelector(selectPageType);
  const navigate = useNavigate();
  useEffect(() => {
    if (mapRef.current) {
      // const degreesToMeters = (coords: Coords) =>
      //   transform(coords, 'EPSG:4326', 'EPSG:3857');
      const map = mapFactory(mapRef);
      const baseView = new View({
        center: [4185385.9208, 3921831.0473],
        zoom: 8,
      });
      if (pageType === 'attestations') {
        RenderAttestationsLayer(map, geojson, baseView);
      }
      if (pageType === 'people') {
        RenderPeopleLayer(map, geojson, baseView);
      }
      if (pageType === 'loci') {
        RenderLociLayer(map, geojson, baseView);
      }
      if (pageType === 'stones') {
        RenderStonesLayer(map, geojson, baseView);
      }
      if (pageType === 'stonework-markings') {
        RenderStoneworkMarkingsLayer(map, geojson, baseView);
      }
      if (pageType === 'styles') {
        RenderStylesLayer(map, geojson, baseView);
      }
      if (pageType === 'workshops') {
        RenderWorkshopsLayer(map, geojson, baseView);
      }
      map.on('click', (event) => {
        const feature = map.forEachFeatureAtPixel(event.pixel, (feature) => {
          return feature;
        });
        if (feature) {
          navigate(`/atlas/${pageType}/${feature?.getProperties().id}`);
        }
      });
      return () => {
        map.dispose();
      };
    }
  }, [mapRef, geojson]);
};

export default useMap;
