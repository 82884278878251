import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const NoMatchImage = styled('img')`
  width: 100%;
`;

export const NoMatchAttribution = styled(Box)`
  text-align: center;
`;

export const NoMatchBackLink = styled(Link)`
  display: block;
  margin-top: 20px;
`;
