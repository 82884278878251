import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FooterBox = styled(Box)(({ theme }) => ({
  background: `${theme.palette.primary.main}`,
  color: `${theme.palette.common.white}`,
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0em',
  left: 0,
  right: 0,
  bottom: 0,
  position: 'static',
  transition: 'all 1s',
  '&.hidden': {
    position: 'absolute',
    bottom: '-150px',
  },
}));

export const InfoContainer = styled('div')`
  border-top: solid 1px white;
  padding: 25px 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 98px;
  div {
    max-width: 50%;
  }
  @media screen and (max-width: 1024px) {
    padding: 15px;
    div {
      font-size: 10px;
    }
  }
`;
