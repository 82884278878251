import ModalProvider from 'mui-modal-provider';
import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router-dom';

import Footer from './modules/common/components/footer/footer.component';
import { Main } from './modules/common/styles/global.styles';

function App() {
  const location = useLocation();
  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.style.height = /\/atlas*/gm.test(location.pathname) ? '100vh' : '';
      body.style.overflow = /\/atlas*/gm.test(location.pathname)
        ? 'hidden'
        : 'auto';
    }
  }, [location]);
  return (
    <HelmetProvider>
      <Main component="main">
        <ModalProvider>
          <Outlet />
          <Footer />
        </ModalProvider>
      </Main>
    </HelmetProvider>
  );
}

export default App;
