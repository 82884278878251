import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LoaderBox = styled(Box)(({ theme }) => ({
  background: `${theme.palette.primary.main}`,
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 999,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
