import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ListIcon from '@mui/icons-material/List';
import TuneIcon from '@mui/icons-material/Tune';
import { Button, ButtonGroup } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { Suspense, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router';

import { getAuthenticatedUser } from '../../../auth/api';
import Navbar from '../../../common/components/navbar/navbar.component';
import {
  MenuButton,
  MenuLink,
} from '../../../common/components/navbar/navbar.styles';
import { selectHideNav, toggleNav } from '../../../common/slices/global.slice';
import useMap from '../../hooks/useMap.hook';
import {
  selectShowList,
  selectShowMenu,
  setShowList,
  setShowMenu,
} from '../../slices/atlas.slice';
import { FullScreenIconWrapper, MapWrapper } from './layout.styles';
const Layout: React.FC = () => {
  const dispatch = useDispatch();
  const hideNav = useSelector(selectHideNav);
  const showMenu = useSelector(selectShowMenu);
  const showList = useSelector(selectShowList);
  const mapRef = useRef<HTMLDivElement>(null);
  useMap(mapRef);
  const { data } = useQuery({
    queryKey: ['user'],
    queryFn: getAuthenticatedUser,
    retry: false,
    refetchOnWindowFocus: true,
  });
  const { id } = useParams();

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/ol@v7.1.0/ol.css"
          type="text/css"
        />
      </Helmet>
      <Navbar classes={hideNav ? 'hidden' : ''}>
        <div style={{ display: 'flex' }}>
          <MenuLink to="/atlas/people">
            <MenuButton>people</MenuButton>
          </MenuLink>
          {data && (
            <>
              <MenuLink to="/atlas/attestations">
                <MenuButton>attestations</MenuButton>
              </MenuLink>
              <MenuLink to="/atlas/loci">
                <MenuButton>loci</MenuButton>
              </MenuLink>
            </>
          )}
          <MenuLink to="/atlas/stones">
            <MenuButton>stones</MenuButton>
          </MenuLink>
          <MenuLink to="/atlas/stonework-markings">
            <MenuButton>stonework markings</MenuButton>
          </MenuLink>
          <MenuLink to="/atlas/styles">
            <MenuButton>styles</MenuButton>
          </MenuLink>
          <MenuLink to="/atlas/workshops">
            <MenuButton>workshops</MenuButton>
          </MenuLink>
        </div>
      </Navbar>
      <MapWrapper ref={mapRef} className={hideNav ? 'full-screen' : ''}>
        <Suspense fallback="loading...">
          <Outlet />
        </Suspense>
        <FullScreenIconWrapper>
          <ButtonGroup>
            <Button variant="contained" onClick={() => dispatch(toggleNav())}>
              <FullscreenIcon />
            </Button>
            {!id && (
              <>
                <Button
                  variant="contained"
                  onClick={() => dispatch(setShowList(!showList))}
                >
                  <ListIcon />
                </Button>
                <Button
                  variant="contained"
                  onClick={() => dispatch(setShowMenu(!showMenu))}
                >
                  <TuneIcon />
                </Button>
              </>
            )}
          </ButtonGroup>
        </FullScreenIconWrapper>
      </MapWrapper>
    </>
  );
};

export default Layout;
