import React, { ReactNode } from 'react';

import Loader from '../loader/loader.component';

type Props = { children: ReactNode; loading: boolean };

const GlobalLoader: React.FC<Props> = ({ children, loading }) => {
  return loading ? <Loader /> : <>{children}</>;
};

export default GlobalLoader;
