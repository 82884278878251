import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AuthWrapper = styled('main')(({ theme }) => ({
  background: `${theme.palette.primary.main}`,
}));

export const AuthContainer = styled(Container)`
  height: calc(100vh - 188px);
  display: flex;
  justify-content: center;
  align-items: center;
`;
