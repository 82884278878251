import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import App from './App';
import Admin from './modules/admin';
import Atlas from './modules/atlas';
import Auth from './modules/auth';
import NoMatch from './modules/common/components/no-match/no-match.component';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { index: true, element: <Navigate to="/atlas" /> },
      Auth,
      Atlas,
      Admin,
      { path: '*', element: <NoMatch /> },
    ],
  },
]);
