import React from 'react';
import { Outlet, RouteObject } from 'react-router';

const CreateLocus = React.lazy(
  () => import('./components/create-locus/create-locus.component'),
);
const EditLocus = React.lazy(
  () => import('./components/edit-locus/edit-locus.component'),
);
const LocusDetails = React.lazy(
  () => import('./components/locus-details/locus-details.component'),
);

const LociList = React.lazy(
  () => import('./components/loci-list/loci-list.component'),
);

const route: RouteObject = {
  path: 'loci',
  element: <Outlet />,
  children: [
    { index: true, element: <LociList /> },
    { path: 'create', element: <CreateLocus /> },
    { path: 'edit/:id', element: <EditLocus /> },
    { path: ':id', element: <LocusDetails /> },
  ],
};

export default route;
