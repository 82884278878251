import React from 'react';
import { Outlet, RouteObject } from 'react-router';

const CreateWorkshop = React.lazy(
  () => import('./components/create-workshop/create-workshop.component'),
);
const EditWorkshop = React.lazy(
  () => import('./components/edit-workshop/edit-workshop.component'),
);
const WorkshopDetails = React.lazy(
  () => import('./components/workshop-details/workshop-details.component'),
);
const WorkshopsList = React.lazy(
  () => import('./components/workshops-list/workshops-list.component'),
);

const route: RouteObject = {
  path: 'workshops',
  element: <Outlet />,
  children: [
    { index: true, element: <WorkshopsList /> },
    { path: 'create', element: <CreateWorkshop /> },
    { path: 'edit/:id', element: <EditWorkshop /> },
    { path: ':id', element: <WorkshopDetails /> },
  ],
};

export default route;
