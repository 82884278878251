import { AppBar, Button, MenuItem, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const MainAppBar = styled(AppBar)(({ theme }) => ({
  background: `${theme.palette.common.white}`,
  top: 0,
  left: 0,
  right: 0,
  zIndex: 99,
  transition: 'all 1s',
  '&.hidden': {
    position: 'absolute',
    top: '-99px',
  },
}));

export const MainToolbar = styled(Toolbar)`
  justify-content: space-between;
`;

export const BrandingLink = styled(Link)`
  max-height: 80px;
  max-width: 250px;
  margin: 5px;
  position: relative;
  display: block;
  &:last-of-type {
    margin-right: auto;
  }
  @media screen and (max-width: 1366px) {
    max-width: 150px;
  }
`;

export const Logo = styled('img')`
  width: 250px;
  height: 80px;
  object-fit: contain;
`;

export const MenuButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  color: `${theme.palette.common.black}`,
  borderLeft: '1px solid #C8C8D6',
  padding: '24px 24px 47px',
  height: '100%',
  whiteSpace: 'nowrap',

  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '19px',
  textAlign: 'center',
  textDecoration: 'none',
  textTransform: 'capitalize',

  '&:hover': {
    textDecoration: 'underline',
  },

  ul: {
    borderRadius: 0,
  },
}));

export const MenuLink = styled(Link)`
  text-decoration: none;
  text-transform: capitalize;
  &:last-child {
    border-right: 1px solid #c8c8d6;
  }
`;

export const MainMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: 0,
  a: {
    color: `${theme.palette.common.black}`,
    padding: '8px 16px',
    textDecoration: 'none',
    width: '100%',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
